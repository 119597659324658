import React from 'react'
import HomeCon from '../HomeMain/HomeCon';
import Why from '../Why nayak/Why';
import Offers from '../offers/Offers';
import Toppers from '../toppers/Toppers';
import Branch from '../search branch/Branch';
import NewOffer from '../../newOffers/NewOffer';
import { FaWhatsapp } from "react-icons/fa";
import './mainhome.css'
import Advantage from '../advantage/Advantage';

const Home = () => {
  return (
    <>
      <div className="wpfloat">

        <HomeCon />
        <Why />
        {/* <NewOffer /> */}
        <Advantage />
        {/* <Toppers /> */}
        <Branch />

        <a href="https://wa.me/+917710010044" target="_blank"><div className="wpicon"><FaWhatsapp id='faWp' size={50} color='white' /></div></a>

      </div>
    </>
  )
}

export default Home