import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from '../Components/Home/Home';

const Pageroute = () => {

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="*">
            <Route path='*' element={<Navigate to='/' />} />
          </Route>

          <Route path='/'>
            <Route index element={<Home />} />
          </Route>

        </Routes>

      </BrowserRouter>
    </>
  )
}

export default Pageroute