import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Pageroute from "./Route/Route";

const App = () => {
  return (
    <>
      <Pageroute />
      {/* <BrowserRouter>
        <header>
          <div className="nav-area" >
            <a href="/#" className="mainLogo"><img src={img}></img> </a>
            <Navbar />
          </div>
          <div className="my-area">
            <NewNav />
          </div>
        </header>
        <HomeCon />

        <Routes>  
        </Routes>
      </BrowserRouter> */}
    </>
  );
};

export default App;